//
//
//

import Lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "@/assets/lottie/feature4.json";
export default {
  data() {
    return {
      lottieOptions: {
        animationData: animationData.default
      }
    };
  },
  mounted() {
    this.$emit("mounted");
  },
  components: {
    Lottie
  }
};